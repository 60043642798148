// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!./externalDependency/bootstrap.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!./externalDependency/font-awesome-4.7.0/css/font-awesome.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\r\n* Only Respective external css packages are imported here\r\n*/\r\n\r\nhtml {\r\n  font-size: 100%;\r\n}\r\n\r\n.w-90 {\r\n  width: 90% !important;\r\n}", "",{"version":3,"sources":["webpack://./src/assets/styles/main.css"],"names":[],"mappings":"AAAA;;CAEC;;AAKD;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["/**\r\n* Only Respective external css packages are imported here\r\n*/\r\n\r\n@import url('./externalDependency/bootstrap.min.css');\r\n@import url('./externalDependency/font-awesome-4.7.0/css/font-awesome.css');\r\n\r\nhtml {\r\n  font-size: 100%;\r\n}\r\n\r\n.w-90 {\r\n  width: 90% !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
